import { createSlice } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from "./store";
import axios from "axios";

interface ListItem {
  id: number;
  title: string;
  url: string;
  description: string;
}
interface ListState {
  items: ListItem[];
}

const initialState: ListState = {
  items: [],
};

export const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },
    addItem: (state, action) => {
      if (action.payload.id) {
        state.items.unshift(action.payload);
        return;
      }

      // Old
      const lastId = state.items.reduce(
        (maxId, current) => (current.id > maxId ? current.id : maxId),
        0
      );
      state.items.unshift({ ...action.payload, id: lastId + 1 });
    },
    updateItem: (state, action) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id
      );
      state.items[index] = action.payload;
    },
    removeItem: (state, action) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id
      );
      state.items.splice(index, 1);
    },
  },
});

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(addAsync({...item}}))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
interface DraftListItem {
  title: string;
  url: string;
  description: string;
}
export const loadItems = () => async (dispatch: AppDispatch) => {
  const { data } = await axios.get("https://api.annealbert.dev/aidir/items");
  dispatch(setItems(data));
};
export const addItemAsync =
  (draftItem: DraftListItem) => async (dispatch: AppDispatch) => {
    const { data } = await axios.post(
      "https://api.annealbert.dev/aidir/item",
      draftItem
    );
    const item = { ...draftItem, id: data.insertId };
    dispatch(addItem(item));
  };
export const updateItemAsync =
  (item: ListItem) => async (dispatch: AppDispatch) => {
    await axios.put(`https://api.annealbert.dev/aidir/item/${item.id}`, item);
    dispatch(updateItem(item));
  };
export const removeItemAsync =
  (item: ListItem) => async (dispatch: AppDispatch) => {
    await axios.delete(`https://api.annealbert.dev/aidir/item/${item.id}`);
    dispatch(removeItem(item));
  };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectItems = (state: RootState) => state.list.items;

export const { setItems, addItem, updateItem, removeItem } = listSlice.actions;

export type { ListItem, DraftListItem };

export default listSlice.reducer;
