import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectItems, loadItems, ListItem } from "./store/listSlice";
import ListItemCard from "./ListItem";
import { Row, Col, Space, Button } from "antd";
import "./ListPage.css";
import { AppDispatch } from "./store/store";
import NewListItem from "./ItemModal";
import { PlusOutlined } from "@ant-design/icons";

function ListPage() {
  const items = useSelector(selectItems);
  const [nullItem] = useState({
    id: 0,
    title: "",
    description: "",
    url: "",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const titleToAdd = searchParams.get("title") || ""; // Else eslint complaints that in useeffect itemToAdd in setModalItem is not the right type. But not for the others. Weirddd
  const urlToAdd = searchParams.get("url");
  const descriptionToAdd = searchParams.get("text");

  useEffect(() => {
    if (titleToAdd || urlToAdd || descriptionToAdd) {
      const itemToAdd = { ...nullItem, title: titleToAdd };
      if (urlToAdd) itemToAdd.url = urlToAdd;
      else if (descriptionToAdd?.startsWith("http"))
        itemToAdd.url = descriptionToAdd;
      else if (descriptionToAdd) itemToAdd.description = descriptionToAdd;

      setModalItem(itemToAdd);
      setModalOpen(true);
      navigate("/", { replace: true });
    }
  }, [titleToAdd, urlToAdd, descriptionToAdd, nullItem, navigate]);

  const [modalItem, setModalItem] = useState(nullItem);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    loadItemsAtStartup(dispatch);
  }, [dispatch]);

  function loadItemsAtStartup(dispatch: AppDispatch) {
    dispatch(loadItems());
  }

  const handleEdit = (item: ListItem) => {
    setModalItem(item);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalItem(nullItem);
    setModalOpen(false);
  };

  return (
    <>
      <Space direction="horizontal" align="center">
        <h1>Items</h1>
        <Button type="text" onClick={() => setModalOpen(true)}>
          <PlusOutlined />
        </Button>
      </Space>
      <Row
        gutter={[32, 32]}
        justify="start"
        style={{
          height: "55vh",
          overflow: "auto",
          padding: "1rem 0",
          scrollbarWidth: "thin",
          scrollbarColor: "grey white",
        }}
        className="RowClass"
      >
        {items.map((item, index) => (
          <Col key={index}>
            <ListItemCard item={item} onEdit={handleEdit} />
          </Col>
        ))}
      </Row>
      <NewListItem
        item={modalItem}
        modalOpen={modalOpen}
        onClose={handleClose}
      />
    </>
  );
}

export default ListPage;
