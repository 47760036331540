import axios from "axios";
import React, { useState } from "react";
import { AppDispatch } from "./store/store";
import {
  setAxiosResponseInterceptor,
  setRefreshtoken,
  setRequestInterceptor,
  setUser,
} from "./store/userSlice";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Layout,
  Row,
  Space,
  Switch,
  Typography,
  theme,
} from "antd";
import { useDispatch } from "react-redux";
import { Header, Content, Footer } from "antd/es/layout/layout";
import "./App.css";

function LoginPage() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const [staySignedIn, setStaySignedIn] = useState(true);

  const login = async (dispatch: AppDispatch) => {
    setLoggingIn(true);

    try {
      const { data } = await axios.post("https://api.annealbert.dev/login", {
        email,
        password,
      });
      const { token, refreshtoken, userId } = data;

      dispatch(setUser({ id: userId, email }));
      dispatch(setRefreshtoken(refreshtoken));

      // Storing refreshtoken in localStorage allows for staying logged in on this pc. Else just store in memory.
      if (staySignedIn) {
        localStorage.setItem("refreshtoken", refreshtoken);
        localStorage.setItem("userId", userId);
        localStorage.setItem("staySignedIn", "true");
      }

      const interceptor = axios.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      });
      dispatch(setRequestInterceptor(interceptor));

      // You could call loadItems here already, but I think it will be called automatically once ListPage is mounted

      dispatch(setAxiosResponseInterceptor());

      const dest = searchParams.get("dest");
      if (dest) navigate(dest);
      else navigate("/");

      setLoggingIn(false);
    } catch (err) {
      setLoggingIn(false);
      console.log(err);
    }
  };

  return (
    <>
      <Layout className="layout">
        <Header>
          <Row justify="space-between" align="bottom">
            <Col>
              <Typography.Text
                code
                style={{ color: "white", fontSize: "1.5rem" }}
              >
                AIDIR
              </Typography.Text>
            </Col>
          </Row>
        </Header>
        <Content style={{ padding: "0 50px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>AIDIR</Breadcrumb.Item>
            <Breadcrumb.Item>Login</Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-content"
            style={{ background: colorBgContainer }}
          >
            <Space direction="vertical">
              <Input
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input.Password
                placeholder="yourpassword"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Space direction="horizontal">
                <Switch
                  defaultChecked
                  onChange={(checked: boolean) => setStaySignedIn(checked)}
                />
                <Typography.Text type="secondary">
                  Stay signed in
                </Typography.Text>
              </Space>
              <Button loading={loggingIn} onClick={() => login(dispatch)}>
                Login
              </Button>
            </Space>
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          AIDIR ©2023 Created by annealbert.dev
        </Footer>
      </Layout>
    </>
  );
}

export default LoginPage;
