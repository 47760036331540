import { createSlice } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from "./store";
import axios from "axios";

interface User {
  id: number | null;
  email: string | null;
}
interface UserState {
  user: User;
  refreshtoken: any;
  isRefreshing: Boolean;
  refreshingCall: any;
  requestInterceptor: any;
  tempStoreToken: any;
}

const initialState: UserState = {
  user: {
    id: null,
    email: null,
  },
  refreshtoken: null,
  isRefreshing: false,
  refreshingCall: null,
  requestInterceptor: null,
  tempStoreToken: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = initialState.user;
      axios.interceptors.request.eject(state.requestInterceptor);
      localStorage.removeItem("staySignedIn");
      localStorage.removeItem("userId");
      localStorage.removeItem("refreshtoken");
    },
    setRequestInterceptor: (state, action) => {
      state.requestInterceptor = action.payload;
    },
    setRefreshingState(state, action) {
      state.isRefreshing = action.payload;
    },
    setRefreshtoken(state, action) {
      state.refreshtoken = action.payload;
    },
    setRefreshingCall(state, action) {
      state.refreshingCall = action.payload;
    },
    setTempToken(state, action) {
      state.tempStoreToken = action.payload;
    },
  },
});

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(addAsync({...item}}))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const setAxiosResponseInterceptor =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    axios.interceptors.request.eject(state.user.requestInterceptor);

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response ? error.response.status : null;

        let retryCount = 0;
        if (status === 401) {
          retryCount += 1;
          if (retryCount >= 3) {
            dispatch(clearUser());
            // TODO: send something back to component or so? How does component know that it should navigate to login page?
          }

          return dispatch(refreshToken()).then(() => {
            error.config.headers[
              "Authorization"
            ] = `Bearer ${state.user.tempStoreToken}`;
            dispatch(setTempToken(null));
            error.config.baseURL = undefined;

            return axios.request(error.config);
          });
        }
        return Promise.reject(error);
      }
    );
    dispatch(setRequestInterceptor(interceptor));
  };

export const refreshToken =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.user.isRefreshing) return state.user.refreshingCall;

    dispatch(setRefreshingState(true));

    const userId = state.user.user.id || localStorage.getItem("userId");
    const refreshtoken =
      state.user.refreshtoken || localStorage.getItem("refreshtoken");

    // Stop if we do not have userId or refreshtoken
    if (!userId || !refreshtoken) {
      dispatch(clearUser());
      return;
    }
    console.log("userId :>> ", userId);
    console.log("refreshtoken :>> ", refreshtoken);

    const refreshingCall = axios
      .get(`https://api.annealbert.dev/user/refresh/${userId}/${refreshtoken}`)
      .then(({ data: { token, refreshtoken: newRefreshtoken } }) => {
        // Set refresh token
        dispatch(setRefreshtoken(newRefreshtoken));
        if (localStorage.getItem("staySignedIn"))
          localStorage.setItem("refreshtoken", newRefreshtoken);

        // Set access token
        axios.interceptors.request.eject(state.user.requestInterceptor);
        const interceptor = axios.interceptors.request.use((config) => {
          config.headers["Authorization"] = `Bearer ${token}`;
          return config;
        });
        dispatch(setRequestInterceptor(interceptor));
        dispatch(setTempToken(token));

        // Set refreshing state and stuff
        dispatch(setRefreshingState(false));
        dispatch(setRefreshingCall(null));

        return Promise.resolve(true);
      });

    dispatch(setRefreshingCall(refreshingCall));
    return refreshingCall;
  };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = (state: RootState) => state.user.user;

export const {
  setUser,
  clearUser,
  setRequestInterceptor,
  setRefreshingState,
  setRefreshtoken,
  setRefreshingCall,
  setTempToken,
} = userSlice.actions;

export type { User };

export default userSlice.reducer;
