import { useDispatch } from "react-redux";
import { addItemAsync, updateItemAsync } from "./store/listSlice";
import { ListItem } from "./store/listSlice";
import { Input, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { AppDispatch } from "./store/store";

interface ListItemCardProps {
  item: ListItem;
  modalOpen: boolean | undefined;
  onClose: () => void;
}

function ListItemCard(props: ListItemCardProps) {
  const { item, modalOpen, onClose } = props;
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (modalOpen) {
      setTitle(item.title);
      setUrl(item.url);
      setDescription(item.description);
    } else setConfirmLoading(false);
  }, [modalOpen, item]);

  const handleOk = async (dispatch: AppDispatch) => {
    setConfirmLoading(true);
    if (item.id === 0)
      await dispatch(addItemAsync({ title, url, description }));
    else
      await dispatch(updateItemAsync({ id: item.id, title, url, description }));
    setConfirmLoading(false);
    onClose();
  };

  const [confirmLoading, setConfirmLoading] = useState(false);

  return (
    <>
      <Modal
        title="New item"
        open={modalOpen}
        onOk={() => handleOk(dispatch)}
        onCancel={() => onClose()}
        confirmLoading={confirmLoading}
      >
        <Space direction="vertical">
          <Input
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Input
            placeholder="www.google.com"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <Input.TextArea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            autoSize
          />
        </Space>
      </Modal>
    </>
  );
}

export default ListItemCard;
