import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store/store";
import ListPage from "./ListPage";
import "./App.css";
import { Breadcrumb, Layout, Typography, theme, Button, Row, Col } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  clearUser,
  selectUser,
  setRefreshtoken,
  setRequestInterceptor,
  setUser,
} from "./store/userSlice";
const { Header, Content, Footer } = Layout;
const { Text } = Typography;

function HomePage() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const tryUserLogin = async () => {
    // In dev mode, apparently React renders components twice, in some way. Supposed to check for possible issues and bugs or sth.
    // For me it is annoying because it sends two requests for refreshtokens that interfere with one another...
    if (user.id) return;

    const staySignedIn = localStorage.getItem("staySignedIn");
    if (!staySignedIn) {
      dispatch(clearUser());
      navigate("/login");
      return;
    }

    try {
      const localUserId = localStorage.getItem("userId");
      const localRefreshToken = localStorage.getItem("refreshtoken");
      const {
        data: { token, refreshtoken },
      } = await axios.get(
        `https://api.annealbert.dev/refresh/${localUserId}/${localRefreshToken}`
      );

      // Set refresh token
      dispatch(setRefreshtoken(refreshtoken));
      if (staySignedIn) localStorage.setItem("refreshtoken", refreshtoken);

      // Set access token
      const interceptor = axios.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      });
      dispatch(setRequestInterceptor(interceptor));

      // Get user info
      const { data: user } = await axios.get(
        `https://api.annealbert.dev/userInfo/${localUserId}`
      );
      dispatch(setUser(user));

      // No need to get data, I think

      // const title = searchParams.get("title");
      // const url = searchParams.get("url");
      // const text = searchParams.get("text");
      // console.log("dest :>> ", dest);
      // console.log("will navigate now to dest or to /");
      // let dest = '/';
      // if (title && url) dest += `?title=${title}&url=${url}&text=${text}`;
      // if (dest) navigate(dest);
      // else navigate("/");
    } catch (err) {
      console.log("err :>> ", err);
      dispatch(clearUser());
      navigate("/login");
    }
  };

  const logout = () => {
    dispatch(clearUser());
    navigate("/login");
  };

  useEffect(() => {
    // dispatch(setAxiosResponseInterceptor());
    tryUserLogin();
  }, []); // eslint-disable-line
  // WHY DISABLE ESLINT: it complains of course about tryUserLogin not being a dependency. Maybe need a different structure for this.

  return (
    <Provider store={store}>
      <Layout className="layout">
        <Header>
          <Row justify="space-between" align="bottom">
            <Col>
              <Text code style={{ color: "white", fontSize: "1.5rem" }}>
                AIDIR
              </Text>
            </Col>
            <Col>
              <Button className="text-right" onClick={() => logout()}>
                Logout
              </Button>
            </Col>
          </Row>
        </Header>
        <Content style={{ padding: "0 20px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>AIDIR</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-content"
            style={{ background: colorBgContainer }}
          >
            <ListPage />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          AIDIR ©2023 Created by annealbert.dev
        </Footer>
      </Layout>
    </Provider>
  );
}

export default HomePage;
