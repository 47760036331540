import { useDispatch } from "react-redux";
import { ListItem, removeItemAsync } from "./store/listSlice";
import { Card, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AppDispatch } from "./store/store";

interface ListItemCardProps {
  item: ListItem;
  onEdit: (listItem: ListItem) => void;
}

function ListItemCard(props: ListItemCardProps) {
  const { item, onEdit } = props;
  const dispatch = useDispatch();

  const handleDeleteItemClick = (dispatch: AppDispatch) => {
    dispatch(removeItemAsync(item));
  };
  const handleGoToClick = () => {
    const url = /^(f|ht)tps?:\/\//i.test(item.url)
      ? item.url
      : `http://${item.url}`;
    window.open(url, "_blank");
  };

  const headStyle = {
    backgroundColor: "#f5f5f5",
  };

  const deleteEl = (
    <Popconfirm
      title="Delete item"
      description="Are you sure you want to delete this item?"
      onConfirm={() => handleDeleteItemClick(dispatch)}
      okText="Yes"
      cancelText="No"
    >
      <DeleteOutlined />
    </Popconfirm>
  );

  return (
    <Card
      key={item.id}
      title={item.title}
      hoverable
      style={{ width: 300 }}
      headStyle={headStyle}
      actions={[
        deleteEl,
        <EditOutlined onClick={() => onEdit(item)} />,
        <div onClick={() => handleGoToClick()}>Go to</div>,
      ]}
    >
      <Card.Meta title={item.description} description={item.url} />
    </Card>
  );
}

export default ListItemCard;
